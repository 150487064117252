import React from "react";
import { IconInterface } from "./Icons.model";

const TelegramIcon: React.FC<IconInterface> = ({ height = 32, width = 32 }) => {
  return (
    <div>
      <svg
        width={width}
        height={height}
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M30.9883 5.23227C30.5726 9.75119 28.7737 20.7172 27.8582 25.7786C27.471 27.9201 26.7084 28.6382 25.9703 28.7084C24.3662 28.8612 23.1478 27.6117 21.594 26.558C19.1626 24.9092 17.789 23.8828 15.4288 22.2739C12.7013 20.4146 14.4695 19.3931 16.0239 17.7222C16.4307 17.2851 23.4991 10.6341 23.6359 10.0307C23.653 9.95527 23.6693 9.67352 23.5074 9.52556C23.3454 9.3776 23.1075 9.42772 22.9354 9.46794C22.6916 9.52525 18.8073 12.1811 11.2827 17.4355C10.1803 18.2186 9.18171 18.6002 8.28693 18.5802C7.30054 18.5582 5.40318 18.0034 3.99261 17.5289C2.26256 16.9472 0.887522 16.6397 1.00727 15.6516C1.06965 15.1369 1.75472 14.6107 3.06247 14.0729C11.1159 10.4432 16.4861 8.05017 19.1731 6.89384C26.8449 3.59272 28.4391 3.01931 29.4781 3.00018C29.7067 2.99626 30.2177 3.0548 30.5486 3.33263C30.7688 3.53055 30.9091 3.80673 30.9419 4.10655C30.9979 4.47882 31.0135 4.85641 30.9883 5.23227Z"
          fill="#555555"
        />
      </svg>
    </div>
  );
};

export default TelegramIcon;
