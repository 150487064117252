import { Suspense } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import styled from "styled-components";
import Footer from "../components/footer/Footer";
import Header from "../components/header/Header";
import appRoutes from "./appRoutes";

const Router = () => {
  return (
    <BrowserRouter>
      <Routes>
        {appRoutes.map((route) => {
          return (
            <Route
              key={route.id}
              path={route.path}
              exact={route.exact}
              element={
                <Suspense
                  fallback={
                    <SuspenseStyle>
                      <SuspenseImage alt="logo" src="/logo/logo.png" />
                    </SuspenseStyle>
                  }
                >
                  <div>
                    <Header />
                    <route.component />
                    <Footer />
                  </div>
                </Suspense>
              }
            />
          );
        })}
      </Routes>
    </BrowserRouter>
  );
};

export default Router;

const SuspenseStyle = styled.div`
  display: "flex";
  height: "100vh";
  width: "100vw";
  justify-content: "center";
  align-items: "center";
`;

const SuspenseImage = styled.img`
  width: "150px";
`;
