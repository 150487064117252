import styled from "styled-components";

export const WhiteLayeredHeaderContaienr = styled.h5`
  position: relative;
  text-align: center;
  margin: 0;
  color: white;
  margin-bottom: 1.1em;
`;

export const BackgroundText = styled.h3`
  font-family: "Pretendard Semi Bold";
  opacity: 5%;
  margin: 0;
`;

export const PrimaryText = styled.h5`
  font-family: "Pretendard Bold";
  margin: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 0.1em;
`;
