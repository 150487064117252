import styled from "styled-components";

export const NavHeader = styled.div`
  display: flex;
  justify-content: end;
`;

export const NavLinksWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  font-family: "Pretendard Regular";
`;
