import { red } from "@mui/material/colors";
import styled from "styled-components";
import { multiResponsive } from "../../../utils/utils";

export const RoadMapContainer = styled.div`
  padding-top: 5em;
  padding-bottom: 5em;
  text-align: center;
  color: white;
  background-image: url("../../../../../backgroundImage/partnersbg.png");
  background-attachment: fixed;
`;

export const PhaseContainer = styled.div`
  width: 100%;
  justify-items: center;
  box-sizing: border-box;
  display: flex;
  ${multiResponsive({
    xs: {
      display: "grid",
      "grid-template-columns": "auto-fill",
      "row-gap": "3em",
      "column-gap": "1em",
    },
    md: {
      display: "grid",
      "grid-template-columns": "auto auto",
      "row-gap": "3em",
    },
    lg: { display: "flex" },
  })}
`;
