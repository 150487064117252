import React, { useEffect } from "react";
import LayeredHeader from "../../../components/layeredHeader/LayeredHeader";
import { Description } from "../../../styles/GlobalStyles";

import PageSize from "../../../styles/pageSize.styles";
import {
  FaqContainer,
  QuestionContainer,
  QuestionsContainer,
} from "./Faq.styles";
import faqData, { FAQInterface } from "./faqData";
import Question from "./Question";
import AOS from "aos";
import "aos/dist/aos.css";

const Faq: React.FC = () => {
  useEffect(() => {
    AOS.init({
      duration: 2500,
      easing: "ease",
      once: false,
      delay: 1,
    });
  }, []);
  return (
    <FaqContainer>
      <PageSize>
        <LayeredHeader title="FAQ" />
        <div data-aos="fade-down" data-aos-delay="70">
          <Description>
      
          </Description>
        </div>
        <div data-aos="fade-down" data-aos-delay="80">
          <QuestionsContainer>
            {faqData.map((faq: FAQInterface) => {
              return (
                <QuestionContainer key={faq.id}>
                  <Question question={faq.question} answer={faq.answer} href={faq?.href}/>
                </QuestionContainer>
              );
            })}
          </QuestionsContainer>
        </div>
      </PageSize>
    </FaqContainer>
  );
};

export default Faq;
