import React from "react";
import { ReactElement } from "react";

export interface FAQInterface {
  id: number;
  question: string;
  answer: string;
  href?: string;
}
const faqData: FAQInterface[] = [
  {
    id: 1,
    question: "What is VINU SPACE?",
    answer:      
      "The VINU SPACE utilizes blockchain technology to combine real and virtual spaces onto one platform. \
      It offers an NFT Marketplace, store ownership system, and various other utilities. Find out more HERE",
    href: "https://docs.vinunetwork.io/vinunetwork/vinu-space",
    
  },
  {
    id: 2,
    question: "What is VNN?",
    answer:
      "VNN is an ERC-20 token used for all transactions within the VINU SPACE ecosystem. \
      The supply of VNN is limited, with a total of 2 billion VNN available ever. \
      VNN holders will be able to benefit from various utilities including: \
      Exclusive access to NFT presales, purchasing VINU NFTs from the marketplace, \
      governance rights and voting power in the protocol, and more to come.",
  },
  {
    id: 3,
    question: "How is blockchain technology integrated with VINU SPACE?",
    answer:
      "Blockchain technology enables franchisers to easily and transparently write contracts with franchisees through utilizing smart contracts, \
      and allows anyone to own and operate stores through NFTs where true ownership is proven on the blockchain. \
      In addition, joint-ownership of various stores is made possible through a fractionated NFTs system. \
      Find out more HERE",
    href: "https://docs.vinunetwork.io/vinunetwork/vinu-space/real-virtual-nfts",
  },
  {
    id: 4,
    question: "Where can I learn more about VINU Network?",
    answer:
      "Make sure to read our VINU Network documentation to get an overview of our project and future plans. \
      For further information, follow our posts on Medium, Twitter and stay connected in our Telegram group chat.",
  },
  {
    id: 5,
    question: "Where can I buy VNN?",
    answer: `VNN tokens can be purchased through the MEXC Global Exchange.`,
  },
  {
    id: 6,
    question: "What is VNN used for?",
    answer:
      "VNN is a key currency in VINU Network's platform and is used throughout the ecosystem for purchases, \
      transactions, and receiving rewards. It can also used within VINU affiliated offline stores through the VINU App.",
  },
];

export default faqData;
