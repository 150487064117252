import React from "react";
import { IconInterface } from "./Icons.model";

const ChevronDownIcon: React.FC<IconInterface> = ({
  width = 16,
  height = 9,
}) => {
  return (
    <div>
      <svg
        width={width}
        height={height}
        viewBox="0 0 16 9"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M14.25 1.5L8 7.75L1.75 1.5"
          stroke="#EFEFEF"
          strokeWidth="1.6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
};

export default ChevronDownIcon;
