import React from "react";
import { IconInterface } from "./Icons.model";

const TwitterIcon: React.FC<IconInterface> = ({ height = 32, width = 32 }) => {
  return (
    <div>
      <svg
        width={width}
        height={height}
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M27.9163 10.0723C27.9354 10.3388 27.9354 10.6054 27.9354 10.8718C27.9354 19 21.7488 28.3655 10.4417 28.3655C6.95813 28.3655 3.7221 27.3566 1 25.6054C1.49494 25.6625 1.97079 25.6815 2.48477 25.6815C5.3591 25.6815 8.00509 24.7107 10.118 23.0546C7.41499 22.9975 5.14974 21.2272 4.36927 18.7906C4.75001 18.8477 5.1307 18.8858 5.53049 18.8858C6.0825 18.8858 6.63457 18.8096 7.1485 18.6764C4.33124 18.1053 2.21823 15.6307 2.21823 12.6421V12.566C3.03673 13.0229 3.98859 13.3084 4.9974 13.3464C3.3413 12.2424 2.25631 10.3579 2.25631 8.22586C2.25631 7.08374 2.56083 6.03679 3.09386 5.12307C6.12053 8.85404 10.6701 11.2906 15.7715 11.5571C15.6764 11.1002 15.6193 10.6244 15.6193 10.1485C15.6193 6.76013 18.3604 4 21.7678 4C23.5381 4 25.137 4.74239 26.2602 5.94163C27.6497 5.67514 28.9822 5.16116 30.1625 4.45686C29.7055 5.88456 28.7348 7.0838 27.4594 7.84517C28.6967 7.71199 29.896 7.36927 31 6.89343C30.1626 8.11166 29.1156 9.19664 27.9163 10.0723Z"
          fill="#555555"
        />
      </svg>
    </div>
  );
};

export default TwitterIcon;
