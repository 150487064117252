import React from "react";
import LoadingIcon from "../../../../assets/icons/LoadingIcon";
import {
  Description,
  InfoButton,
  PrimaryButton,
} from "../../../../styles/GlobalStyles";
import ReactModal from "../../../reactModal/ReactModal";
import { ModalInterface } from "../WalletModal";
import { ModalTitle } from "../WalletModal.styles";
import {
  ConnectWalletButtonContainer,
  ConnectWalletModalContainer,
  IconContainer,
} from "./ConnectYourWallet.styles";

const ConnectYourWalletModal: React.FC<ModalInterface> = ({
  isOpen,
  handleClose,
}) => {
  return (
    <div>
      <ReactModal isOpen={isOpen} handleClose={handleClose}>
        <ConnectWalletModalContainer>
          <ModalTitle>지갑 연결하기</ModalTitle>

          <IconContainer>
            <LoadingIcon />
          </IconContainer>

          <Description>
            카이카스 지갑 연동을 위해 <br />
            카이카스 확장 프로그램을 먼저 설치해야 합니다.
          </Description>

          <ConnectWalletButtonContainer>
            <InfoButton>뒤로가기</InfoButton>
            <PrimaryButton>카이카스 설치하기</PrimaryButton>
          </ConnectWalletButtonContainer>
        </ConnectWalletModalContainer>
      </ReactModal>
    </div>
  );
};

export default ConnectYourWalletModal;
