import React from "react";
import { IconInterface } from "./Icons.model";

const BookIcon: React.FC<IconInterface> = ({ width = 32, height = 32 }) => {
  return (
    <div>
      <svg
        width={width}
        height={height}
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M14.4036 25.2585C14.9035 25.2585 15.3534 25.6584 15.3534 26.2083C15.3534 26.7082 14.9535 27.1581 14.4036 27.1581C13.9037 27.1581 13.4538 26.7582 13.4538 26.2083C13.4538 25.6584 13.9037 25.2585 14.4036 25.2585ZM29.1006 19.4597C28.6007 19.4597 28.1508 19.0597 28.1508 18.5099C28.1508 18.01 28.5507 17.56 29.1006 17.56C29.6005 17.56 30.0504 17.96 30.0504 18.5099C30.0504 19.0097 29.6005 19.4597 29.1006 19.4597ZM29.1006 15.6104C27.5009 15.6104 26.2012 16.9102 26.2012 18.5099C26.2012 18.8098 26.2512 19.1097 26.3512 19.4097L16.8031 24.5086C16.2532 23.7088 15.3534 23.2589 14.4036 23.2589C13.3038 23.2589 12.3041 23.9087 11.8042 24.8585L3.20592 20.3595C2.30611 19.8596 1.60625 18.4099 1.70623 17.0102C1.75622 16.3103 2.00617 15.7604 2.3561 15.5605C2.60604 15.4105 2.85599 15.4605 3.20592 15.6104L3.25591 15.6604C5.55544 16.8602 13.0039 20.7594 13.3038 20.9094C13.8037 21.1093 14.0537 21.2093 14.9035 20.8094L30.3004 12.811C30.5503 12.711 30.8002 12.5111 30.8002 12.1612C30.8002 11.7112 30.3503 11.5113 30.3503 11.5113C29.4505 11.1114 28.1008 10.4615 26.8011 9.86163C24.0016 8.5619 20.8023 7.0622 19.4026 6.31236C18.2028 5.66249 17.203 6.21238 17.0531 6.31236L16.7031 6.46233C10.3545 9.66167 1.95618 13.8108 1.45628 14.1108C0.606456 14.6107 0.0565688 15.6604 0.00657909 16.9602C-0.0934004 19.0097 0.956384 21.1593 2.45608 21.9092L11.5542 26.6082C11.7542 28.0079 13.0039 29.1077 14.4036 29.1077C16.0033 29.1077 17.253 27.8579 17.303 26.2583L27.301 20.8594C27.8009 21.2593 28.4507 21.4592 29.1006 21.4592C30.7003 21.4592 32 20.1595 32 18.5598C32 16.9102 30.7003 15.6104 29.1006 15.6104Z"
          fill="#555555"
        />
      </svg>
    </div>
  );
};

export default BookIcon;
