import { ThemeProvider } from "styled-components";
import Router from "./router/Router";
import { GlobalStyle } from "./styles/GlobalStyles";
import theme from "./styles/theme";
import { Helmet } from 'react-helmet';

// mindon : 메타태그(메타데이터)를 여기서 설정해줌, 라이브만 적용되게함.
function MetaTags() {  
  const currentDomain = window.location.hostname;
  if (currentDomain.includes("fastfive") || currentDomain.includes("localhost")) {
    return null;
  }
  return (
    <div>
      <Helmet>
        <title>VINU Network</title>
        <meta name="description" content="VINU Network is a real economy metaverse based on stores existing in the real world." />
        <meta name="keywords" content="unmanedstore,nft,metaverse,storenft,storemetaverse,blockchain" />
        <meta name="robots" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="vinunetwork.io" />
        <meta property="og:site_name" content="VINU NETWORK" />
        <meta property="og:title" content="Vinu Network" />
        <meta property="og:description" content="A real economy metaverse based on stores existing in the real world." />
        <meta property="og:image" content="https://s3.ap-northeast-2.amazonaws.com/vinunetwork.metadata/VINUNetwork_og_400x210.png" />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:title" content="VINU network" />
        <meta name="twitter:description" content="OWN, EARN, AND EXPERIENCE on VINU Network's Metaverse." />
      </Helmet>
      {/* 페이지 내용 */}
    </div>
  );
}

function App() {
  return (
    <ThemeProvider theme={theme}>
      <MetaTags />
      <GlobalStyle />
      <Router />
    </ThemeProvider>
  );
}

export default App;
