import React from "react";
import BookIcon from "../../assets/icons/BookIcon";
import MediumIcon from "../../assets/icons/MediumIcon";
import TelegramIcon from "../../assets/icons/TelegramIcon";
import TwitterIcon from "../../assets/icons/TwitterIcon";
import PageSize from "../../styles/pageSize.styles";
import {
  FooterContainer,
  FooterFlexContainer,
  FooterRightContainer,
  IconsContainer,
  Logo,
  SingleIconContainer,
  Terms,
} from "./Footer.styles";

interface SocialLinks {
  id: number;
  icon: React.FC;
  link: string;
}

const socialLinks: SocialLinks[] = [
  {
    id: 1,
    icon: TelegramIcon,
    link: "https://t.me/VINU_Network",
  },
  {
    id: 2,
    icon: TwitterIcon,
    link: "https://twitter.com/VINU_Network",
  },
  {
    id: 3,
    icon: MediumIcon,
    link: "https://medium.com/@VINU_Network",
  },
  {
    id: 4,
    icon: BookIcon,
    link: "https://docs.vinunetwork.io/vinunetwork",
  },
];

const Footer: React.FC = () => {
  const handleIconClick = (link: string) => {
    window.open(link);
  };

  return (
    <FooterContainer>
      <PageSize>
        <FooterFlexContainer>
          <Logo src="/logo/whiteLogo.png" alt="logo" />
          <Terms>COPYRIGHT (c) 2023 VINU Network</Terms>

          <FooterRightContainer>
            <IconsContainer>
              {socialLinks.map((social: SocialLinks) => {
                const Icon = social.icon;
                return (
                  <SingleIconContainer
                    key={social.id}
                    onClick={() => handleIconClick(social.link)}
                  >
                    <Icon />
                  </SingleIconContainer>
                );
              })}
            </IconsContainer>
          </FooterRightContainer>
        </FooterFlexContainer>
      </PageSize>
    </FooterContainer>
  );
};

export default Footer;
