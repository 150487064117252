import React from "react";
import {
  BackgroundText,
  LayeredHeaderContaienr,
  PrimaryText,
} from "./layeredHeader.styles";

interface LayeredHeaderProps {
  title: string;
}

const LayeredHeader: React.FC<LayeredHeaderProps> = ({ title }) => {
  return (
    <LayeredHeaderContaienr>
      <BackgroundText>{title}</BackgroundText>
      <PrimaryText>{title}</PrimaryText>
    </LayeredHeaderContaienr>
  );
};

export default LayeredHeader;
