import styled from "styled-components";

export const AboutHeading = styled.h5`
  font-family: "Pretendard Bold";
  margin: 0;
`;

export const AboutContainer = styled.div`
  text-align: center;
  padding: 4rem 0px;
  margin-left: auto;
  margin-right: auto;
  background-color: white;
`;

export const AboutTitle = styled.h6`
  color: ${(props: any) => props.theme.success.five};
  margin-top: 4px;
  margin-bottom: 0px;
  font-family: "Pretendard Bold";
`;
