import { Transform } from "stream";
import styled from "styled-components";
import { multiResponsive } from "../../../utils/utils";

export const AboutHeading = styled.h5`
  font-family: "Pretendart Semi Bold";
  margin: 0;
`;

export const AboutContainer = styled.div`
  text-align: center;
  padding: 3em 0px;
  margin-left: auto;
  margin-right: auto;
  background-color: #030a08;
  color: white;
`;

export const AboutTitle = styled.h6`
  color: ${(props: any) => props.theme.success.five};
  margin-top: 4px;
  margin-bottom: 0px;
  font-family: "Pretendart Bold";
`;
export const ArrayBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${multiResponsive({
    xs: {
      "flex-direction": "column-reverse",
    },
    md: {},
    lg: { "flex-direction": "row" },
  })}
`;
export const ArrayContainer = styled.div`
  width: 50%;
  ${multiResponsive({
    xs: {
      "flex-direction": "column-reverse",
      width: "100%",
    },
    md: {},
    lg: { width: "50%" },
  })}
`;
export const VideoContainer = styled.div`
  height: 450px;
  width: 50%;
`;
export const ImgContainer = styled.img`
  position: absolute;
  width: 350px;
  height: 450px;
  left: 50%;
  transform: translateX(-50%);
`;

export const VideoBox = styled.div`
  position: relative;
  height: 100%;
  width: 350px;
  left: 50%;
  transform: translateX(-50%);
`;
export const Italic = styled.span`
  font-style: italic;
  font-weight: bold;
`;
