import React from "react";
import { description } from "../roadMapData";
import {
  PhaseText,
  RoadMapCardContainer,
  RoadMapDescription,
  RoadMapDescriptionContainer,
  RoadMapTitle,
} from "./RoadMapCard.styles";

interface RoadmapCardInterface {
  title: string;
  phase: string;
  description: description[];
}

const RoadMapCard: React.FC<RoadmapCardInterface> = ({
  title,
  phase,
  description,
}) => {
  return (
    <RoadMapCardContainer>
      <PhaseText>{phase}</PhaseText>
      <RoadMapTitle>{title}</RoadMapTitle>
      <RoadMapDescriptionContainer>
        {description.map((desc, index) => {
          return (
            <RoadMapDescription key={index}>{desc.step}</RoadMapDescription>
          );
        })}
      </RoadMapDescriptionContainer>
    </RoadMapCardContainer>
  );
};

export default RoadMapCard;
