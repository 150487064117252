import styled from "styled-components";

export const FaqContainer = styled.div`
  background-color: ${(props: any) => props.theme.gray.eight};
  padding-top: 3em;
  padding-bottom: 3em;
  color: white;
  text-align: center;
`;

export const FaqTitle = styled.h6`
  text-align: center;
`;

export const QuestionsContainer = styled.div`
  margin-top: 3em;
`;

export const QuestionContainer = styled.div`
  cursor: pointer;
  border-top: 1px solid ${(props: any) => props.theme.gray.seven};
  text-align: left;
  border-bottom: 1px solid ${(props: any) => props.theme.gray.seven};
`;

export const SingleQuestionContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const QestionIconContainer = styled.div``;

export const ClosedQuestion = styled.h6`
  font-size: 16px;
  margin-bottom: 1rem;
  margin-top: 1rem;
  color: ${(props: any) => props.theme.info};
  font-family: "Pretendard Bold";
  &:hover {
    color: #01aae280;
  }
`;

export const OpenQuestion = styled.h6`
  color: ${(props: any) => props.theme.success.five};
  font-family: "Pretendard Bold";
  font-size: 16px;
  margin-top: 1rem;
  margin-bottom: 1rem;
`;

export const Answer = styled.p`
  font-family: "Pretendard Regular";
  font-size: 16px;
  margin-top: 0;
`;
