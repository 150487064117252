import { Link } from "react-router-dom";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { X } from "react-feather";
import { NavHeader, NavLinksWrapper } from "./navModal.styles";
import headerData from "../headerData";
import { RightLink } from "../header.styles";

interface PopListInterface {
  id: number;
  title: string;
}

interface IProps {
  isOpen: boolean;
  linkPopList: PopListInterface[];
  languagePop: PopListInterface[];
  handleClose: () => void;
  openWalletModal: () => void;
}
const handleLinkVisit = (link: string) => {
  window.open(link);
};

const handleView = (id: string) => {
  const element: HTMLElement | null = document.getElementById(id);
  element && element.scrollIntoView({ behavior: "smooth" });
};
const NavLinks = [
  { id: "home", name: "Home", path: "/", refrence: "home" },
  { id: "nft", name: "NFT", path: "/nft", refrence: "nft" },
  {
    id: "metaverse",
    name: "VINU SPACE",
    path: "/metaverse",
    refrence: "metaverse",
  },
  {
    id: "roadmap",
    name: "ROADMAP",
    path: "/roadmap",
    refrence: "roadmap",
  },
  {
    id: "whitepaper",
    name: "WHITEPAPER",
    link: "/https://docs.vinunetwork.io/vinunetwork/",
  },
];

export const style = {
  position: "absolute" as "absolute",
  top: "0",
  right: "0",
  width: "100%",
  maxWidth: "400px",
  height: "100vh",
  bgcolor: "#1a1a1a",
  outline: "none",
  boxShadow: 24,
  color: "white",
  padding: "18px 30px",
  boxSizing: "border-box",
};

const NavModal: React.FC<IProps> = ({
  isOpen,
  linkPopList,
  languagePop,
  handleClose,
  openWalletModal,
}) => {

  const clickMe = () => {
    const currentDomain = window.location.hostname;
    console.log(currentDomain); 

    // currentDomain에 "fastfive"나 "localhost"가 포함되어 있으면 fastfive.nft.vinunetwork.io로 이동
    if (currentDomain.includes("fastfive") || currentDomain.includes("localhost")) {
      window.open("https://fastfive-nft.vinunetwork.io");
    } else {
      window.open("https://nft.vinunetwork.io");
    }
    
  };


  return (
    <div>
      <Modal open={isOpen} onClose={handleClose}>
        <Box sx={style}>
          <NavHeader>
            <X color="white" onClick={handleClose} />
          </NavHeader>

          <NavLinksWrapper>
            {headerData.map((header) => {
              return (
                <NavLinksWrapper
                  // onClick={() => handleRouteClick(header.link)}
                  onClick={() => {
                    if (header.link) {
                      handleLinkVisit(header.link);
                    } else {
                      handleClose();
                      handleView(header.refrence ?? "");
                    }
                  }}
                  key={header.id}
                >
                  {header.name}
                </NavLinksWrapper>
              );
            })}
            <RightLink onClick={clickMe}>VINU NFT</RightLink>
          </NavLinksWrapper>
          <div style={{ marginTop: "60px" }}></div>
        </Box>
      </Modal>
    </div>
  );
};

export default NavModal;
