import React from "react";
import {
  BackgroundText,
  WhiteLayeredHeaderContaienr,
  PrimaryText,
} from "./whitelayeredHeader.styles";

interface whiteLayeredHeaderProps {
  title: string;
}

const whiteLayeredHeader: React.FC<whiteLayeredHeaderProps> = ({ title }) => {
  return (
    <WhiteLayeredHeaderContaienr>
      <BackgroundText>{title}</BackgroundText>
      <PrimaryText>{title}</PrimaryText>
    </WhiteLayeredHeaderContaienr>
  );
};

export default whiteLayeredHeader;
