import LayeredHeader from "../../../components/layeredHeader/LayeredHeader";
import { Description, DescriptionHeading } from "../../../styles/GlobalStyles";
import PageSize from "../../../styles/pageSize.styles";
import { AboutContainer } from "./Own.styles";
import AOS from "aos";
import "aos/dist/aos.css";
import React, { useEffect } from "react";

const OwnSection: React.FC = () => {
  useEffect(() => {
    AOS.init({
      duration: 2000,
      easing: "ease",
      once: false,
      delay: 1,
    });
  }, []);
  return (
    <AboutContainer>
      <PageSize>
        <div data-aos="fade-down" data-aos-delay="50">
          <LayeredHeader title="OWN, EARN, AND EXPERIENCE" />
        </div>

        <div data-aos="fade-down" data-aos-delay="50">
          <Description>
            The VINU SPACE provides a one-stop startup <br />
            protocol that allows anyone to easily start a business.
            <br />
            <br />
            Users can get a sneak peek of what starting a real store is like as they <br />
            experience operating a virtual store, and earn rewards while doing so!
          </Description>
        </div>
      </PageSize>
    </AboutContainer>
  );
};

export default OwnSection;
