import React from "react";
import LoadingIcon from "../../../../assets/icons/LoadingIcon";
import { InfoButton } from "../../../../styles/GlobalStyles";
import ReactModal from "../../../reactModal/ReactModal";
import { ModalInterface } from "../WalletModal";
import { ModalTitle } from "../WalletModal.styles";
import {
  ButtonContainer,
  IconContainer,
  LoadingDescription,
  LoadintText,
} from "./LoadingModal.styles";

const LoadingModal: React.FC<ModalInterface> = ({ isOpen, handleClose }) => {
  return (
    <div>
      <ReactModal isOpen={isOpen} handleClose={handleClose}>
        <>
          <ModalTitle>지갑 연결하기</ModalTitle>
          <IconContainer>
            <LoadingIcon />
          </IconContainer>
          <LoadintText>연결중...</LoadintText>
          <LoadingDescription>
            지갑 연결이 안되시나요? 브라우저에서 카이카스 재설치 후 다시
            실행해주세요.
          </LoadingDescription>

          <ButtonContainer>
            <InfoButton>닫기</InfoButton>
          </ButtonContainer>
        </>
      </ReactModal>
    </div>
  );
};

export default LoadingModal;
