import styled from "styled-components";

export const ConnectWalletModalContainer = styled.div`
  text-align: center;
`;

export const IconContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const ConnectWalletButtonContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1em;
`;
