import styled from "styled-components";
import { multiResponsive } from "../../../../utils/utils";

export const RoadMapCardContainer = styled.div`
  background-color: rgba(3, 10, 8, 0.85);
  margin-top: 2rem;
  width: fit-content;
  padding: 1em;
  box-sizing: border-box;
  height: 100%;
  text-align: left;
  ${multiResponsive({
    // sm: { width: "370px", border: "2px solid yellow" },
    md: { width: "380px" },
    lg: { width: "100%" },
    xs: { width: "330px" },
  })};
`;

export const PhaseText = styled.p`
  font-style: italic;
  font-size: 0.8rem;
`;

export const RoadMapTitle = styled.h6`
  color: ${(props: any) => props.theme.success.five};
  margin-top: 4px;
  margin-bottom: 0px;
  font-family: "Pretendard Extra Bold";
`;

export const RoadMapDescriptionContainer = styled.div`
  margin-top: 25px;
`;

export const RoadMapDescription = styled.p`
  margin: 8px;
  margin-left: 0;
  font-family: "Pretendard Regular";
  color: white;
`;
