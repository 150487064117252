const theme = {
  base: {
    black: "#000000",
    white: "#ffffff",
  },

  gray: {
    nine: "#111111",
    eight: "#1a1a1a",
    seven: "#4a4a4a",
    six: "7a7a7a",
    five: "a7a7a7",
    four: "efefef",
  },

  info: "#01aae2",

  success: {
    nine: "030a08",
    seven: "#92ada6",
    five: "#00efa4",
  },
};

export default theme;
