import styled from "styled-components";
import { multiResponsive } from "../../utils/utils";

export const FooterContainer = styled.div`
  width: 100vw;
  background-color: ${(props: any) => props.theme.gray.nine};
  padding-top: 15px;
  padding-bottom: 15px;
  color: white;
`;

export const FooterFlexContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${multiResponsive({
    xs: { "flex-direction": "column", gap: "2rem" },
    md: { "flex-direction": "row" },
  })}
`;

export const Logo = styled.img`
  height: 40px;
  ${multiResponsive({
    xs: { margin: "0 0 0 0" },
    md: { margin: "0 46px 0 0" },
    lg: { margin: "0 46px 0 0" },
  })}
`;

export const FooterRightContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const Terms = styled.p`
  font-size: 0.8rem;
  font-weight: 200;
  width: 100%;
  text-align: center;
  color: rgb(85, 85, 85);
`;

export const IconsContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const SingleIconContainer = styled.div`
  margin-left: 1em;
  cursor: pointer;
`;
