import React from "react";
import { IconInterface } from "./Icons.model";

const MediumIcon: React.FC<IconInterface> = ({ width = 32, height = 32 }) => {
  return (
    <div>
      <svg
        width={width}
        height={height}
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9.46094 7C4.78811 7 1 10.8143 1 15.519C1 20.2237 4.78839 24.0379 9.46094 24.0379C14.1335 24.0379 17.9219 20.224 17.9219 15.519C17.9219 10.814 14.134 7 9.46094 7ZM22.9727 7.49903C20.6362 7.49903 18.7424 11.0905 18.7424 15.5191C18.7424 19.9477 20.6365 23.5394 22.9729 23.5394C25.3094 23.5394 27.2035 19.9479 27.2035 15.5191C27.2035 11.0893 25.3093 7.49903 22.9727 7.49903ZM29.512 8.33439C28.6905 8.33439 28.0244 11.5513 28.0244 15.5191C28.0244 19.4869 28.69 22.7038 29.512 22.7038C30.334 22.7038 31 19.486 31 15.519C31 11.551 30.3336 8.33439 29.512 8.33439Z"
          fill="#555555"
        />
      </svg>
    </div>
  );
};

export default MediumIcon;
