import styled from "styled-components";

export const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
`;

export const LoadintText = styled.h6`
  text-align: center;
  margin-top: 0;
  margin-bottom: 30px;
`;

export const LoadingDescription = styled.p`
  margin: 0;
  text-align: center;
`;

export const ButtonContainer = styled.div`
  margin-top: 20px;
  width: 200px;
  margin-left: auto;
  margin-right: auto;
`;
