import React, { useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";
import vnnDescriptionData from "../vnnDescriptionData";
import {
  VnnGradient,
  VnnBox,
  VnnTextBox,
  VnnText,
  VnnImg,
  // VnnHiddenImg,
} from "../Vnn.styles";

export interface ImageCardProps {
  id: number;
  image: string;
  description: string;
  title: string;
  setHoveredCard: any;
  hoveredCard: number | null;
}

const ImageLeftCard: React.FC<ImageCardProps> = ({
  id,
  image,
  description,
  title,
  setHoveredCard,
  hoveredCard,
}) => {
  const { ref, inView } = useInView();
  const [showColor, setShowColor] = useState<boolean>(false);

  useEffect(() => {
    if (hoveredCard) {
      if (hoveredCard === id) {
        setShowColor(true);
      } else {
        setShowColor(false);
      }
    } else {
      if (inView) {
        setShowColor(true);
      } else {
        setShowColor(false);
      }
    }
  }, [hoveredCard, inView, id]);

  return (
    <VnnBox
      className={`${showColor ? "notGray" : "gray"}`}
      ref={ref}
      onMouseEnter={() => setHoveredCard(id)}
      onMouseLeave={() => setHoveredCard(null)}
      // className={`px-4 lg:px-0 ${
      //   showColor ? "filter: grayscale(0)" : "filter: grayscale(100%)"
      // }`}
    >
      {/* <div>
        <img
          src={image}
          alt={title}
          style={{ height: "auto", maxWidth: "100%" }}
        />
      </div> */}
      {/* <div>
        <img style={{ height: "auto", maxWidth: "100%" }} />
      </div> */}

      <VnnImg src={image} alt={title}></VnnImg>
      <VnnTextBox className="lg:text-left">
        <VnnGradient>{title}</VnnGradient>
        <VnnText>{description}</VnnText>
      </VnnTextBox>
    </VnnBox>
  );
};

export default ImageLeftCard;
