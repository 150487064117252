import React, { useState } from "react";
import { ButtonsContainer, ModalTitle } from "./WalletModal.styles";
import {
  ButtonInnerContainer,
  ButtonText,
  GradientButton,
  InfoButton,
  PrimaryButton,
} from "../../../styles/GlobalStyles";
import WalletIcon from "../../../assets/icons/WalletIcon";
import ReactModal from "../../reactModal/ReactModal";
import LoadingModal from "./loadingModal/LoadingModal";
import QrModal from "./qrModal/QrModal";
import ConnectYourWalletModal from "./connectYourWalletModal/ConnectYourWalletModal";

export interface ModalInterface {
  isOpen: boolean;
  handleClose: any;
}

const WalletModal: React.FC<ModalInterface> = ({ isOpen, handleClose }) => {
  const [openLoadingModal, setOpenLoadingModal] = useState<boolean>(false);
  const [openQrModal, setOpenQrModal] = useState<boolean>(false);
  const [openConnectWalletModal, setOpenConnectWalletModal] =
    useState<boolean>(false);

  const closeLoadingModal = () => setOpenLoadingModal(false);
  const closeQrModal = () => setOpenQrModal(false);
  const closeConnectWalletModal = () => setOpenConnectWalletModal(false);

  return (
    <div>
      <ReactModal isOpen={isOpen} handleClose={handleClose}>
        <>
          <ModalTitle>지갑 연결하기</ModalTitle>

          <ButtonsContainer>
            <PrimaryButton onClick={() => setOpenLoadingModal(true)}>
              <ButtonInnerContainer>
                <WalletIcon />
                <ButtonText>카이카스</ButtonText>
              </ButtonInnerContainer>
            </PrimaryButton>

            <GradientButton
              onClick={() => setOpenQrModal(true)}
              style={{ marginTop: "10px" }}
            >
              <ButtonInnerContainer>
                <WalletIcon />
                <ButtonText>카이카스 모바일</ButtonText>
              </ButtonInnerContainer>
            </GradientButton>

            <InfoButton
              onClick={() => handleClose()}
              style={{ marginTop: "25px", width: "70%" }}
            >
              뒤로가기
            </InfoButton>
          </ButtonsContainer>

          <LoadingModal
            handleClose={closeLoadingModal}
            isOpen={openLoadingModal}
          />

          <QrModal handleClose={closeQrModal} isOpen={openQrModal} />

          <ConnectYourWalletModal
            isOpen={openConnectWalletModal}
            handleClose={closeConnectWalletModal}
          />
        </>
      </ReactModal>
    </div>
  );
};

export default WalletModal;
