import React, { useState } from "react";
import Collapsible from "react-collapsible";
import ChevronDownIcon from "../../../assets/icons/ChevronDownIcon";
import ChevronUpIcon from "../../../assets/icons/ChevronUpIcon";
import {
  Answer,
  ClosedQuestion,
  OpenQuestion,
  QestionIconContainer,
  SingleQuestionContainer,
} from "./Faq.styles";

interface QuestionInterface {
  question: string;
  answer: string;
  href?: string;
}

const Question: React.FC<QuestionInterface> = ({ question, answer, href }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleRenderQuestion = () => {
    if (isOpen) {
      return (
        <SingleQuestionContainer>
          <OpenQuestion>{question}</OpenQuestion>
          <QestionIconContainer>
            <ChevronUpIcon />
          </QestionIconContainer>
        </SingleQuestionContainer>
      );
    } else {
      return (
        <SingleQuestionContainer>
          <ClosedQuestion>{question}</ClosedQuestion>
          <QestionIconContainer>
            <ChevronDownIcon />
          </QestionIconContainer>
        </SingleQuestionContainer>
      );
    }
  };

  // mindon : 여기서 answer에 HERE가 있으면 링크로 바꿔줌
  const renderAnswer = (text: string) => {
    const regex = /HERE/g;
    const splitText = text.split(regex);

    if (splitText.length <= 1) {
      return <Answer>{text}</Answer>;
    }

    const matches = text.match(regex);

    return (
      <Answer>
        {splitText.map((item, index) => (
          <>
            {item}
            {index !== splitText.length - 1 && (
              <a href={href} style={{ color: 'white' }} target="_blank" rel="noopener noreferrer">{matches![index]}</a>
            )}
          </>
        ))}
      </Answer>
    );
  };

  
  return (
    <div>
      <Collapsible
        onOpen={() => setIsOpen(true)}
        onClose={() => setIsOpen(false)}
        trigger={<div>{handleRenderQuestion()}</div>}
      >
        {renderAnswer(answer)}
      </Collapsible>
    </div>
  );
};

export default Question;
