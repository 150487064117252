import React from "react";
import Marquee from "react-fast-marquee";
import firstPartner from "../../../assets/partners/1st.png";
import secondPartner from "../../../assets/partners/2nd.png";
import thirdPartner from "../../../assets/partners/3rd.png";
import fourthPartner from "../../../assets/partners/4th.png";
import fifthPartner from "../../../assets/partners/5th.png";
import sixthPartner from "../../../assets/partners/6th.png";
import seventhPartner from "../../../assets/partners/7th.png";
import eighthPartner from "../../../assets/partners/8th.png";
import ninthPartner from "../../../assets/partners/9th.png";
import tenthParnter from "../../../assets/partners/10th.png";
import eleventhPartner from "../../../assets/partners/11th.png";
import twelthpartner from "../../../assets/partners/12th.png";
import thirteenthPartner from "../../../assets/partners/13th.png";
import fourteenthPartner from "../../../assets/partners/14th.png";
import fifteenthPartner from "../../../assets/partners/15th.png";
import sixteenthPartner from "../../../assets/partners/16th.png";
import seventeenthPartner from "../../../assets/partners/17th.png";
import eighteenthPartner from "../../../assets/partners/18th.png";
import ninteenthPartner from "../../../assets/partners/19th.png";
import twentyeethPartner from "../../../assets/partners/20th.png";
import twentyFirstPartneer from "../../../assets/partners/21th.png";
import twentySecondPartner from "../../../assets/partners/22th.png";
import twentyThirdPartner from "../../../assets/partners/23rd.png";
import WhiteLayeredHeader from "../../../components/whitelayeredHeader/whiteLayeredHeader";

const partnerList = [
  { image: firstPartner },
  { image: secondPartner },
  { image: thirdPartner },
  { image: fourthPartner },
  { image: fifthPartner },
  { image: sixthPartner },
  { image: seventhPartner },
  { image: eighthPartner },
  { image: ninthPartner },
  { image: tenthParnter },
  { image: eleventhPartner },
  { image: twelthpartner },
  { image: thirteenthPartner },
  { image: fourteenthPartner },
  { image: fifteenthPartner },
  { image: sixteenthPartner },
  { image: seventeenthPartner },
  { image: eighteenthPartner },
  { image: ninteenthPartner },
  { image: twentyeethPartner },
  { image: twentyFirstPartneer },
  { image: twentySecondPartner },
  { image: twentyThirdPartner },
];

const Partners = () => {
  return (
    <div
      style={{
        paddingTop: "80px",
        paddingBottom: "80px",
        backgroundColor: "black",
      }}
    >
      <WhiteLayeredHeader title="PARTNERS" />
      <div>
        <Marquee gradient={false} speed={60}>
          {partnerList.map((img, index) => {
            return (
              <img
                style={{ marginLeft: "32px" }}
                key={index}
                src={img.image}
                alt="partner"
              />
            );
          })}
        </Marquee>
      </div>
    </div>
  );
};

export default Partners;
