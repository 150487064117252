import styled from "styled-components";

export const HeaderContainer = styled.div`
  width: 100vw;
  background-color: white;
  padding-top: 15px;
  padding-bottom: 15px;
  position: fixed;
  z-index: 9;
`;

export const HeaderLinksContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const LeftLinks = styled.div`
  display: flex;
  align-items: center;
`;

export const Logo = styled.img`
  height: 48px;
`;

export const HeaderLinkContainer = styled.div`
  margin-left: 50px;
`;

export const HeaderLink = styled.p`
  color: black;
  cursor: pointer;
  font-family: "Pretendard Bold";
`;

export const MobileHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const RightLink = styled.div`
  padding: 8px 16px;
  color: #32bdeb;
  font-weight: bold;
  text-align: center;
  border: 1px solid #32bdeb;
  border-radius: 90px;
  &:hover {
    cursor: pointer;
    background-color: #32bdeb;
    color: white;
  }
`;
