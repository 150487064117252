import { blue } from "@mui/material/colors";
import styled from "styled-components";
import { multiResponsive } from "../../../utils/utils";

export const VnnContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 5rem;
  justify-content: space-between;
`;

export const VnnBox = styled.div`
  display: flex;
  margin-bottom: 3rem;
  justify-content: space-around;
  align-items: center;

  ${multiResponsive({
    xs: { "flex-direction": "column" },
    lg: {
      "margin-top": "0",
      "text-align": "left",
      "flex-direction": "row",
    },
  })};

  &.gray {
    filter: grayScale(100%);
  }
`;

export const VnnTextBox = styled.div`
  text-align: center;
  margin-top: 3px;
  ${multiResponsive({
    xs: { width: "100%" },
    lg: {
      "margin-top": "0",
      "text-align": "left",
      width: "40%",
    },
  })};
`;

export const VnnText = styled.p`
  margin-top: 0.75rem;
  line-height: 1.4;
  font-family: "Pretendard Regular";
`;

export const VnnImg = styled.img`
  height: auto;
  max-width: 100%;
  margin-bottom: 0.75rem;
`;
export const VnnGradient = styled.h5`
  background: -webkit-linear-gradient(
    60deg,
    ${(props) => props.theme.info},
    ${(props) => props.theme.success.five}
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: "Pretendard Bold";
  margin-bottom: 0px;
  margin-top: 0;
  // font-size: 1;
`;

export const VnnHiddenImg = styled.img`
  ${multiResponsive({
    xs: { display: "none" },
    lg: {
      display: "block",
    },
  })};
`;

export const VnnBlockImg = styled.img`
  height: auto;
  max-width: 100%;
  margin-bottom: 0.75rem;
  ${multiResponsive({
    xs: { display: "block" },
    lg: {
      display: "none",
    },
  })};
`;
