import firstImage from "../../../assets/vnnDescription/first.png";
import secondImage from "../../../assets/vnnDescription/second.png";
import thirdImage from "../../../assets/vnnDescription/third.png";
import fourthImage from "../../../assets/vnnDescription/fourth.png";

export interface VnnDescriptionData {
  id: number;
  title: string;
  description: string;
  image: string;
}

const vnnDescriptionData: VnnDescriptionData[] = [
  {
    id: 1,
    title: "OWN & OPERATE TO EARN VNN",
    description:
      "VNN is the native token used within the VINU Network ecosystem. VNN can be earned and used through utilizing the VINU Space’s store management system, NFT transactions, and offline store activities.",
    image: firstImage,
  },
  {
    id: 2,
    title: "JOINT OWNERSHIP & DAO",
    description:
      "Stores can be jointly owned, making ownership easily accessible for all our users. VINU Network’s DAO is owned and governed by the NFT holders, and impactful decisions are made collectively through a voting mechanism.",
    image: secondImage,
  },
  {
    id: 3,
    title: "COLLECTION & MEMBERSHIP",
    description:
      "Collect and experience various unmanned stores. Become a member through contributing to the ecosystem and gain access to exclusive on-offline benefits.",
    image: thirdImage,
  },
  {
    id: 4,
    title: "START-UP SUPPORT",
    description:
      "Some of the revenue generated through NFT sales is used to support new start-ups that open stores in real life, after experiencing store operation in the VINU SPACE. Also, stores that receive subsidies can become partners with VINU Network.",
    image: fourthImage,
  },
];

export default vnnDescriptionData;
