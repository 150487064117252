import { useState } from "react";
import { useNavigate } from "react-router";
import { Menu } from "react-feather";
import ChevronDownIcon from "../../assets/icons/ChevronDownIcon";
import {
  ButtonInnerContainer,
  ButtonText,
  GradientButton,
  RoundGradientButton,
} from "../../styles/GlobalStyles";
import PageSize from "../../styles/pageSize.styles";
import {
  HeaderContainer,
  HeaderLink,
  HeaderLinkContainer,
  HeaderLinksContainer,
  LeftLinks,
  RightLink,
  Logo,
  MobileHeader,
} from "./header.styles";
import headerData from "./headerData";
import WalletModal from "./walletModal/WalletModal";
import useWindowSize from "../../utils/useWindowSize";
import NavModal from "./navModal/NavModal";

const Header: React.FC = () => {
  const navigate = useNavigate();
  const windowSize = useWindowSize();

  const [isNavModalOpened, setIsNavModalOpened] = useState<boolean>(false);
  const [openWalletModal, setOpenWalletModal] = useState<boolean>(false);

  const handleModalClose = () => setOpenWalletModal(false);
  const handleNavModalClose = () => setIsNavModalOpened(false);

  const handleLinkVisit = (link: string) => {
    window.open(link);
  };

  const handleView = (id: string) => {
    const element: HTMLElement | null = document.getElementById(id);
    element && element.scrollIntoView({ behavior: "smooth" });
  };

  const handleRouteClick = (link: string) => {
    navigate(link);
  };
  const clickMe = () => {
    const currentDomain = window.location.hostname;
    console.log(currentDomain); 

    // currentDomain에 "fastfive"나 "localhost"가 포함되어 있으면 fastfive.nft.vinunetwork.io로 이동
    if (currentDomain.includes("fastfive") || currentDomain.includes("localhost")) {
      window.open("https://fastfive-nft.vinunetwork.io");
    } else {
      window.open("https://nft.vinunetwork.io");
    }
    
  };

  const LinkPopList = [
    {
      id: 1,
      title: "VINU Network",
    },
    {
      id: 2,
      title: "Discord",
    },
    {
      id: 3,
      title: "Twitter",
    },
    {
      id: 4,
      title: "Telegram",
    },
    {
      id: 5,
      title: "Medium",
    },
  ];

  const languagePop = [
    {
      id: 1,
      title: "English",
    },
    { id: 2, title: "한국어" },
  ];

  if (windowSize?.width < 1024) {
    return (
      <>
        <HeaderContainer>
          <PageSize>
            <MobileHeader>
              <Logo src="/logo/logo.png" alt="logo" />
              <Menu color="black" onClick={() => setIsNavModalOpened(true)} />
            </MobileHeader>
          </PageSize>
        </HeaderContainer>

        <NavModal
          isOpen={isNavModalOpened}
          handleClose={handleNavModalClose}
          linkPopList={LinkPopList}
          languagePop={languagePop}
          openWalletModal={() => setOpenWalletModal(true)}
        />
        <WalletModal handleClose={handleModalClose} isOpen={openWalletModal} />
      </>
    );
  }

  return (
    <HeaderContainer>
      <PageSize>
        <HeaderLinksContainer>
          <LeftLinks>
            <Logo src="/logo/logo.png" alt="logo" />
            {headerData.map((header) => {
              return (
                <HeaderLinkContainer
                  // onClick={() => handleRouteClick(header.link)}
                  onClick={() => {
                    if (header.link) {
                      handleLinkVisit(header.link);
                    } else {
                      handleView(header.refrence ?? "");
                    }
                  }}
                  key={header.id}
                >
                  <HeaderLink>{header.name}</HeaderLink>
                </HeaderLinkContainer>
              );
            })}
          </LeftLinks>
          <RightLink onClick={clickMe}>VINU NFT</RightLink>
        </HeaderLinksContainer>
      </PageSize>

      <WalletModal handleClose={handleModalClose} isOpen={openWalletModal} />
    </HeaderContainer>
  );
};

export default Header;
