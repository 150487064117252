import React from "react";
import LayeredHeader from "../../../components/layeredHeader/LayeredHeader";
import { Description } from "../../../styles/GlobalStyles";
import PageSize from "../../../styles/pageSize.styles";
import { PhaseContainer, RoadMapContainer } from "./RoadMap.styles";
import RoadMapCard from "./roadMapCard/RoadMapCard";
import roadMapData, { RoadMapInterface } from "./roadMapData";

const RoadMapSection: React.FC = () => {
  return (
    <RoadMapContainer id="roadmap">
      <LayeredHeader title="ROADMAP" />
      <div data-aos="fade-down" data-aos-delay="50">
        <Description>
          INTRODUCING AN ALL-NEW BUSINESS MODEL AND THE EXCITING PLANS{" "}
          <br />
          WE HAVE IN STORE FOR VINU NETWORK.
        </Description>
      </div>
      <PageSize>
        <PhaseContainer>
          {roadMapData.map((roadMap: RoadMapInterface) => {
            return (
              <div data-aos="fade-down" data-aos-delay="70">
                <RoadMapCard
                  key={roadMap.id}
                  title={roadMap.title}
                  phase={roadMap.phase}
                  description={roadMap.description}
                />
              </div>
            );
          })}
        </PhaseContainer>
      </PageSize>
    </RoadMapContainer>
  );
};

export default RoadMapSection;
