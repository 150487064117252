import WhiteLayeredHeader from "../../../components/whitelayeredHeader/whiteLayeredHeader";
import { Description, DescriptionHeading } from "../../../styles/GlobalStyles";
import PageSize from "../../../styles/pageSize.styles";
import arrayNftImage from "../../../assets/arrynftSection/mobile.png";
import mobileVideo from "../../../assets/arrynftSection/vinuMobile.mp4";
import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

import {
  AboutContainer,
  ArrayContainer,
  ImgContainer,
  VideoContainer,
  VideoBox,
  ArrayBox,
  Italic,
} from "./array.styles";
import { Video } from "react-feather";
const ArraySection: React.FC = () => {
  useEffect(() => {
    AOS.init({
      duration: 2000,
      easing: "ease",
      once: false,
      delay: 1,
    });
  }, []);
  return (
    <AboutContainer id="nft">
      <PageSize>
        <ArrayBox>
          <ArrayContainer>
            <div data-aos="fade-right" data-aos-delay="50">
              <WhiteLayeredHeader title="AN ARRAY OF NFTS" />
              <Description>
                Users can select store NFTs that are based on real-life stores
                according to their interests, ranging from cafes to laundromats,
                and obtain the know-how necessary for operation along with the{" "}
                <Italic>membership benefits</Italic> of each{" "}
                <Italic>store brand.</Italic> <br />
                <br />
                <br />
                Users will also have uniquely designed Vinubots as mascots in
                their stores. Vinubots possess different reward-earning
                potential according to their <Italic>Power</Italic> and{" "}
                <Italic>Rarity.</Italic>
              </Description>
            </div>
          </ArrayContainer>
          <VideoContainer>
            <VideoBox>
              <video
                autoPlay={true}
                muted
                playsInline={true}
                loop
                style={{
                  width: "200px",
                  position: "absolute",
                  top: "95px",
                  left: "50px",
                  zIndex: "9",
                }}
              >
                <source type="video/mp4" src={mobileVideo} />
              </video>
              <ImgContainer src={arrayNftImage} alt="mobile" />
            </VideoBox>
          </VideoContainer>
        </ArrayBox>
      </PageSize>
    </AboutContainer>
  );
};

export default ArraySection;
