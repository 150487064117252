export interface description {
  step: string;
}

export interface RoadMapInterface {
  id: number;
  phase: string;
  title: string;
  description: description[];
}

const roadMapData: RoadMapInterface[] = [
  {
    id: 1,
    phase: "Phase 01",
    title: "LAUNCH",
    description: [
      { step: "Website and Social Media Release" },
      {
        step: "VNN token Issuance",
      },
      {
        step: "NFT Marketplace Exchange System Development",
      },
      {
        step: "Partnership with 740 Real Stores",
      },
    ],
  },
  {
    id: 2,
    phase: "Phase 02",
    title: "GROWTH",
    description: [
      {
        step: "NFT Presale",
      },
      {
        step: "NFT Marketplace Beta Testing",
      },
      {
        step: "MLO Technology Advancement",
      },
      {
        step: "VINU Wallet Development",
      },
      {
        step: "DAO & Governance Mechanism Development",
      },
      {
        step: "Establishment of Further Partnerships",
      },
    ],
  },
  {
    id: 3,
    phase: "Phase 03",
    title: "MATURITY",
    description: [
      {
        step: "NFT Marketplace Grand Launch",
      },
      {
        step: "Premium Membership System Development",
      },
      {
        step: "DAO Governance Beta Testing",
      },
      {
        step: "KLO Technology Advancement",
      },
      {
        step: "VINU Wallet Launch",
      },
      {
        step: "Real-Virtual Metaverse NFT Presale",
      },
      {
        step: "Establishment of Further Partnerships",
      },
    ],
  },
  {
    id: 4,
    phase: "Phase 04",
    title: "EVOLUTION",
    description: [
      {
        step: "Membership’s On-Offline Benefits",
      },
      {
        step: "Real-Virtual Metaverse NFT Issuance",
      },
      {
        step: "Real-life Stores with Mixed On-Offline Technology",
      },
      {
        step: "Joint-ownership Store System Implementation",
      },
      {
        step: "Transparent Operation of DAO Governance",
      },
      {
        step: "Metaverse Operating Platform",
      },
      {
        step: "In-metaverse Stores Opening by Partners",
      },
    ],
  },
];

export default roadMapData;
