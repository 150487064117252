import styled from "styled-components";

export const ModalTitle = styled.h6`
  text-align: center;
  margin-top: 0px;
  margin-bottom: 30px;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 60%;
  margin: auto auto;
`;
