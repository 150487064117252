const BREAK_POINTS:any = {
    xs: 1,
    sm: 640,
    md: 768,
    lg: 1024,
    xl: 1280,
    xxl: 1536,
  };

export const responsive = (breakpoint: string, cssProp: string, cssPropValue: string):any =>{
    if (!breakpoint || !cssProp || !cssPropValue || !BREAK_POINTS[breakpoint]) {
      return "";
    }

    const style = `@media screen and (min-width: ${BREAK_POINTS[breakpoint]}px) {
      ${cssProp}: ${cssPropValue};
    }`;

    return style;   
}

export const multiResponsive = (value:any) => {
    const breakpointList = Object.keys(BREAK_POINTS);
  
    let styles = "";
    breakpointList.forEach((breakpoint) => {
      if (!value[breakpoint]) return;
  
      const [keys, values] = [
        Object.keys(value[breakpoint]),
        Object.values(value[breakpoint]),
      ];
  
      let innerStyles = "";
  
      keys.forEach((key, i) => {
        innerStyles += `${key}: ${values[i]};`;
      });
  
      styles += `@media screen and (min-width: ${BREAK_POINTS[breakpoint]}px) {
         ${innerStyles}
      }`;
    });
  
    return styles;
  }
  

