import React, { useEffect, useState } from "react";
import { ImageCardProps } from "../imageLeftCard/ImageLeftCard";
import { useInView } from "react-intersection-observer";
import {
  VnnGradient,
  VnnBox,
  VnnTextBox,
  VnnText,
  VnnImg,
  VnnHiddenImg,
  VnnBlockImg,
} from "../Vnn.styles";
import { Filter } from "react-feather";
import { color } from "@mui/system";

const ImageRightCard: React.FC<ImageCardProps> = ({
  id,
  title,
  image,
  description,
  setHoveredCard,
  hoveredCard,
}) => {
  const { ref, inView } = useInView();
  const [showColor, setShowColor] = useState<boolean>(true);

  useEffect(() => {
    if (hoveredCard) {
      if (hoveredCard === id) {
        setShowColor(true);
      } else {
        setShowColor(false);
      }
    } else {
      if (inView) {
        setShowColor(true);
      } else {
        setShowColor(false);
      }
    }
  }, [hoveredCard, inView, id]);

  return (
    // <div
    //   ref={ref}
    //   onMouseEnter={() => setHoveredCard(id)}
    //   onMouseLeave={() => setHoveredCard(null)}
    //   className={`flex flex-col lg:flex-row justify-around items-center lg:space-x-12 px-4 lg:px-0 ${
    //     showColor ? "filter: grayscale(0)" : "filter: grayscale(100%)"
    //   }`}
    // >
    <VnnBox
      className={`${showColor ? "notGray" : "gray"}`}
      ref={ref}
      onMouseEnter={() => {
        setHoveredCard(id);
      }}
      onMouseLeave={() => {
        setHoveredCard(null);
      }}
    >
      <VnnBlockImg src={image} alt={title}></VnnBlockImg>
      <VnnTextBox>
        <VnnGradient>{title}</VnnGradient>
        <VnnText>{description}</VnnText>
      </VnnTextBox>
      <VnnHiddenImg src={image} alt={title}></VnnHiddenImg>
    </VnnBox>
  );
};

export default ImageRightCard;
