import styled from "styled-components";

export const LoadingModalContainer = styled.div`
  text-align: center;
`;

export const SubHeading = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
`;

export const ImageContainer = styled.div`
  margin-top: 30px;
  margin-bottom: 30px;
  display: flex;
  justify-content: center;
`;

export const QrImage = styled.img``;

export const ButtonContainer = styled.div`
  width: 70%;
  margin-left: auto;
  margin-right: auto;
`;
