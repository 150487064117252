const headerData = [
  {
    id: "home",
    name: "HOME",
    // link: "/home",
    path: "/",
    refrence: "home",
  },
  {
    id: "nft",
    name: "NFT",
    // link: "/nft-project",
    path: "/nft",
    refrence: "nft",
  },
  {
    id: "metaverse",
    name: "VINU SPACE",
    // link: "/metaverse",
    path: "/metaverse",
    refrence: "metaverse",
  },
  {
    id: "roadmap",
    name: "ROADMAP",
    // link: "/roadmap"
    path: "/roadmap",
    refrence: "roadmap",
  },
  {
    id: "whitepaper",
    name: "WHITEPAPER",
    path: "/white-paper",
    link: "https://docs.vinunetwork.io/vinunetwork/",
  },
];

export default headerData;
