import OwnSection from "./ownSection/ownSection";
import RoadMapSection from "./roadMapSection/RoadMapSection";
import TopSection from "./topSection/TopSection";
import ArraySection from "./arraySection/arraySection";
import VnnDescription from "./vnnDescription/VnnDescription";
import Partners from "./partners/Partners";
import Faq from "./faq/Faq";
const About: React.FC = () => {
  return (
    <div>
      <TopSection />
      <OwnSection />
      <ArraySection />
      <VnnDescription />
      <Partners />
      <RoadMapSection />
      <Faq />
    </div>
  );
};

export default About;
