import styled, { createGlobalStyle } from "styled-components";
import { multiResponsive } from "../utils/utils";

export const GlobalStyle = createGlobalStyle`
 h1{
  font-size: 4.25rem;
  line-height: 5rem;
 }

 h2{
  font-size: 4rem;
  line-height: 4.375rem;
 }

 h3{
  font-size: 3.25rem;
  line-height: 3.375rem;
 }

 h4{
  font-size: 2.625rem;
  line-height: 3.125rem;
 }

 h5{
  font-size:2.5rem;
  line-height:2.5rem;
 }

 h6{
  font-size: 1.5rem;
  line-height: 1.875rem;
 }
`;

export const RoundGradientButton = styled.button`
  color: black;
  outline: none;
  border: none;
  background: linear-gradient(
    20deg,
    ${(props) => props.theme.info},
    ${(props) => props.theme.success.five}
  );
  cursor: pointer;
  border-radius: 100%;
  padding: 7px;
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const GradientButton = styled.button`
  border-radius: 100px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  cursor: pointer;
  color: black;
  outline: none;
  border: none;
  background: linear-gradient(
    20deg,
    ${(props) => props.theme.info},
    ${(props) => props.theme.success.five}
  );
  width: 100%;
`;

export const PrimaryButton = styled.button`
  background-color: ${(props) => props.theme.success.five};
  border-radius: 100px;
  outline: none;
  border: none;
  padding: 10px 3em;
  width: 100%;
  cursor: pointer;
`;

export const InfoButton = styled.button`
  background-color: ${(props) => props.theme.info};
  border-radius: 100px;
  outline: none;
  border: none;
  padding: 10px 3em;
  width: 100%;
  cursor: pointer;
`;

export const ButtonText = styled.p`
  margin-left: 10px;
  margin-top: 0;
  margin-bottom: 0;
  margin-right: 0;
`;

export const ButtonInnerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const GradientText = styled.h1`
  margin-top: -70px;
  background: -webkit-linear-gradient(
    60deg,
    ${(props) => props.theme.info},
    ${(props) => props.theme.success.five}
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: "Pretendard Bold";
  ${multiResponsive({
    xs: { "font-size": "3rem" },
    md: { "font-size": "4rem" },
    lg: { "font-size": "5rem" },
  })}
`;

export const GradientSubHeader = styled.h6`
  background: -webkit-linear-gradient(
    60deg,
    ${(props) => props.theme.info},
    ${(props) => props.theme.success.five}
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: "Pretendard Bold";
`;

export const SuccessText = styled.p`
  margin: 0;
  color: ${(props) => props.theme.success.five};
`;

export const DescriptionHeading = styled.p`
  font-family: "Pretendard Bold";
  font-size: 20px;
`;

export const Description = styled.p`
  font-family: "Pretendard Regular";
  line-height: 1.5;
  font-weight: bold;
  ${multiResponsive({
    xs: { "font-size": "14px" },
    md: { "font-size": "14px" },
    lg: { "font-size": "16px" },
  })};
`;
