import React from "react";
import CountUp from "react-countup";
import backgroundVideo from "../../../assets/arrynftSection/homeTopVideo.mp4";
import {
  Description,
  GradientText
} from "../../../styles/GlobalStyles";
import {
  CountBox,
  CountName,
  CountNumber,
  HomeTopContainer,
  MainVideo,
  WelcomeText,
} from "./TopSection.styles";

const userData = [
  {
    user: 740,
    type: "Affiliated Stores",
  },
  {
    user: 2,
    type: "Global Users",
  },
  {
    user: 187,
    type: "Clients",
  },
  {
    user: 13,
    type: "Countries",
  },
];

const TopSection: React.FC = () => {
  return (
    <HomeTopContainer id="home">
      <MainVideo>
        <div
          style={{ overflowX: "hidden", height: "100vh", overflowY: "hidden" }}
        >
          <video autoPlay muted loop playsInline={true}>
            <source type="video/mp4" src={backgroundVideo} />
          </video>
        </div>
      </MainVideo>
      <div
        style={{
          position: "absolute",
          top: "50%",
          transform: "translateY(-50%)",
        }}
      >
        <WelcomeText>Welcome to</WelcomeText>
        <GradientText style={{ marginBottom: "0" }}>VINU Network</GradientText>
        <Description>
          The blockchain hub for real-virtual unmanned stores.
        </Description>
        <CountBox>
          {userData.map((data, index) => {
            return (
              <div key={index}>
                <div>
                  <CountNumber>
                    {/* {data.user} + */}
                    <CountUp end={data.user} duration={5} />
                    {data.user === 2 && "M"} +
                  </CountNumber>
                </div>
                <CountName>{data.type}</CountName>
              </div>
            );
          })}
        </CountBox>
      </div>
    </HomeTopContainer>
  );
};

export default TopSection;
