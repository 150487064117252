import React from "react";
import {
  Description,
  InfoButton,
  PrimaryButton,
  SuccessText,
} from "../../../../styles/GlobalStyles";
import ReactModal from "../../../reactModal/ReactModal";
import { ModalInterface } from "../WalletModal";
import { ModalTitle } from "../WalletModal.styles";
import {
  ButtonContainer,
  ImageContainer,
  LoadingModalContainer,
  QrImage,
  SubHeading,
} from "./QrModal.styles";

const QrModal: React.FC<ModalInterface> = ({ isOpen, handleClose }) => {
  return (
    <div>
      <ReactModal isOpen={isOpen} handleClose={handleClose}>
        <LoadingModalContainer>
          <ModalTitle>지갑 연결하기</ModalTitle>

          <SubHeading>
            남은 시간{" "}
            <SuccessText style={{ marginLeft: "10px" }}> 09 : 59</SuccessText>
          </SubHeading>

          <ImageContainer>
            <QrImage alt="QR" src="/qr/qr.png" />
          </ImageContainer>

          <Description>QR코드를 스캔하여 연결해 주세요</Description>

          <ButtonContainer>
            <InfoButton>뒤로가기</InfoButton>
            <PrimaryButton style={{ marginTop: "10px" }}>닫기</PrimaryButton>
          </ButtonContainer>
        </LoadingModalContainer>
      </ReactModal>
    </div>
  );
};

export default QrModal;
