import React, { useState } from "react";
import ImageLeftCard from "./imageLeftCard/ImageLeftCard";
import ImageRightCard from "./imageRightCard/ImageRightCard";
import vnnDescriptionData from "./vnnDescriptionData";
import PageSize from "../../../styles/pageSize.styles";
import { VnnContainer, VnnGradient } from "./Vnn.styles";
const VnnDescription: React.FC = () => {
  const [hoveredCard, setHoveredCard] = useState(null);

  return (
    <div id="metaverse" className="py-5 lg:py-20">
      <PageSize>
        <VnnContainer>
          <div>
            {vnnDescriptionData.map((vnn, index) => {
              return (
                <div style={{ marginTop: "10px" }} key={index}>
                  {index % 2 === 0 ? (
                    <ImageRightCard
                      id={vnn.id}
                      title={vnn.title}
                      image={vnn.image}
                      description={vnn.description}
                      setHoveredCard={setHoveredCard}
                      hoveredCard={hoveredCard}
                    />
                  ) : (
                    <ImageLeftCard
                      id={vnn.id}
                      title={vnn.title}
                      image={vnn.image}
                      description={vnn.description}
                      setHoveredCard={setHoveredCard}
                      hoveredCard={hoveredCard}
                    />
                  )}
                </div>
              );
            })}
          </div>
        </VnnContainer>
      </PageSize>
    </div>
  );
};

export default VnnDescription;
