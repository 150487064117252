import styled from "styled-components";
import { multiResponsive } from "../../../utils/utils";

export const HomeTopContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  width: 100vw;
  flex-direction: column;
  color: white;
  text-align: center;
`;
export const MainVideo = styled.div`
  position: relative;
  width: 100%;
`;
export const WelcomeText = styled.p`
  font-family: "Lato", sans-serif;
  ${multiResponsive({
    xs: { "font-size": "3rem" },
    md: { "font-size": "4rem" },
    lg: { "font-size": "5rem" },
  })}
`;

// 카운터 색 : ff5100, 28c5d6
export const CountBox = styled.div`
  color: #28c5d6;
  ${multiResponsive({
    xs: {
      display: "grid",
      "grid-template-columns": "auto auto",
      gap: "1rem",
      width: "90%",
      margin: "0 auto",      
    },
    lg: {
      display: "grid",
      gap: "0",
      width: "100%",
      "grid-template-columns": "auto auto auto auto",
    },
  })}
`;


export const CountNumber = styled.p`
  color: #28c5d6;
  border: 1px solid #28c5d6;
  font-weight: 700;  
  margin: 0;
  padding: 16px;
  ${multiResponsive({
    xs: {
      "font-size": "14px",
    },
    md: {
      "font-size": "2rem",
    },
    lg: {
      "font-size": "3rem",
    },
  })}
`;

export const CountName = styled.p`
  font-size: 12px;
  font-family: "Pretendard Regular";
`;
