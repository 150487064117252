import About from "../pages/about/About";

const appRoutes = [
  {
    id: "home",
    path: "/",
    exact: true,
    component: About,
  },
];

export default appRoutes;
