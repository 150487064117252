import React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";

export const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "#1a1a1a",
  outline: "none",
  boxShadow: 24,
  color: "white",
  borderRadius: "10px",
  p: 4,
};

export interface ModalInterface {
  isOpen: boolean;
  handleClose: any;
  children: any;
}

const ReactModal: React.FC<ModalInterface> = ({
  isOpen,
  handleClose,
  children,
}) => {
  return (
    <div>
      <Modal
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>{children}</Box>
      </Modal>
    </div>
  );
};

export default ReactModal;
